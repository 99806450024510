export default function Footer() {
  return (
    <footer>
      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="col-md-12 ">
              <div className="cont">
                <h3>
                  {" "}
                  <strong className="multi"> Oh, Goodie! App 2023</strong>
                  <br />
                </h3>
              </div>
            </div>
            {/* <div className="col-md-12">
                <ul className="social_icon">
                  <li>
                    <a href="/">
                      <i className="fa fa-facebook" aria-hidden="true" />
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <i className="fa fa-twitter" aria-hidden="true" />
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <i className="fa fa-linkedin" aria-hidden="true" />
                    </a>
                  </li>
                </ul>
              </div> */}
          </div>
        </div>
        <div className="copyright">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <p>
                  Copyright 2023 All Rights Reserved -{" "}
                  <a href="https://ohgoodie.app/"> Oh Goodie Ltd.</a>
                </p>
                <h5 style={{ color: "#fff", textAlign: "center" }}>
                  developed and maintained by Nir Pisahov:{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="mailto:2001nir@gmail.com"
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    2001nir@gmail.com
                  </a>
                </h5>
              </div>
            </div>

            <div
              className="policies"
              style={{
                textAlign: "center",
                color: "#fff",
                paddingTop: "10px",
              }}
            >
              {" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://ohgoodie.app/terms-and-conditions.html"
              >
                Terms & Conditions
              </a>{" "}
              |{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://ohgoodie.app/accessibility-statement.html"
              >
                Accessibility Statement
              </a>{" "}
              |{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://ohgoodie.app/privacy-policy.html"
              >
                Privacy Policy
              </a>{" "}
              |{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://ohgoodie.app/acceptable-use-policy.html"
              >
                Acceptable Use Policy
              </a>{" "}
              |{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://ohgoodie.app/disclaimer.html"
              >
                Disclaimer
              </a>{" "}
              |{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://ohgoodie.app/dmca-policy.html"
              >
                DMCA Policy
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
