import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Navigate } from "react-router-dom";

// custom fonts
import "./fonts/Roundy_Rainbows.ttf";
import BrandingPage from "./BrandingPage";

import { RouterProvider, createBrowserRouter } from "react-router-dom";

// jqueries
//...

const router = createBrowserRouter([
  {
    path: "/",
    Component: App,
  },
  {
    path: "/supporting-local-businesses",
    Component: BrandingPage,
    caseSensitive: false,
  },
  {
    path: "*",
    element: <Navigate to={"/"} />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    {/* <App /> */}
    {/* <BrandingPage /> */}
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
