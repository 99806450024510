import React from "react";
import styles from "./BrandingPage.module.css";
import ReactPlayer from "react-player";
import Footer from "./Footer";
import { Link } from "react-router-dom";

export default function BrandingPage() {
  return (
    <React.Fragment>
      <header className={styles.header}>
        <div className={styles.mainTitleContainer}>
          <Link to={"/"}>
            <h1>Oh, Goodie!</h1>
          </Link>
          <h2>Supporting Local Businesses</h2>
        </div>
        <nav>
          <Link to={"/"}>Back To Homepage</Link>
          {/* <a href="/"></a> */}
          <a
            href="mailto:support@ohgoodie.app"
            target="_blank"
            rel="noreferrer"
          >
            Support
          </a>
        </nav>
      </header>

      <main className={styles.main}>
        {/* <h3>Supporting Local Businesses</h3> */}
        <div className={styles.introductionContainer}>
          <section>
            <h4>introduction</h4>

            <p>
              In today's globalized world, supporting local businesses has
              emerged as a vital practice that brings numerous benefits to
              communities.
            </p>
            <p>
              Local businesses contribute to the economic, social, and cultural
              fabric of a region, fostering community growth, job creation, and
              unique experiences.
            </p>
            <p>
              To further enhance this support, the advent of punch card apps has
              revolutionized the way consumers engage with local businesses.
              This essay explores the multifaceted advantages of supporting
              local businesses and demonstrates how a punch card app can
              effectively facilitate and amplify these benefits.
            </p>
          </section>

          <ReactPlayer
            url={"videos/Oh, Goodie! small biz video CLUB.mp4"}
            controls={true}
            width={"90%"}
            height={null}
            style={{
              maxWidth: "600px",
            }}
          />
        </div>

        <div className={styles.summaryItemsContainer}>
          <SummaryItem
            icon={"images/collaborations.svg"}
            title={"Unique Experiences and Cultural Preservation"}
            text={
              "Oh, Goodie! punch card app can act as a virtual guide, connecting users with local businesses that offer authentic experiences, encouraging them to explore and engage with the cultural richness of their community."
            }
            href={"#unique-experiences-and-cultural-preservation"}
          />
          <SummaryItem
            icon={"images/sales.svg"}
            title={"Strengthening Local Supply Chains"}
            text={
              "Oh, Goodie! punch card app can showcase and promote these relationships, highlighting the collaborative ecosystem of local businesses."
            }
            href={"#strengthening-local-supply-chains"}
          />
          <SummaryItem
            icon={"images/empowering.svg"}
            title={"Empowering Small Businesses"}
            text={
              "Oh, Goodie! app can offer marketing exposure, customer analytics, and loyalty program management tools that may have been inaccessible otherwise."
            }
            href={"#empowering-small-businesses"}
          />
        </div>

        <div id="economic-advantages" className={styles.paragraph}>
          <h3>
            <span>Economic</span> Advantages
          </h3>

          <section>
            <p>
              Supporting local businesses is a powerful driver of local economic
              growth.
            </p>
            <p>
              By patronizing local establishments, consumers contribute to job
              creation and economic stability within their communities.
            </p>
            <p>
              Local businesses often source their products and services locally,
              creating a ripple effect that stimulates the regional economy.
            </p>
            <p>
              A punch card app can encourage repeat visits and customer loyalty
              by offering rewards and discounts, thus ensuring a steady stream
              of revenue for local businesses and facilitating sustained
              economic growth.
            </p>
          </section>
        </div>

        <div
          id="unique-experiences-and-cultural-preservation"
          className={styles.paragraph}
        >
          <h3>Unique Experiences and Cultural Preservation</h3>
          <section>
            <p>
              Local businesses are often rooted in the cultural heritage and
              identity of a community.
            </p>
            <p>
              They provide distinctive products, services, and experiences that
              showcase the local flavor and charm. From quaint coffee shops to
              artisanal boutiques, these businesses foster a sense of place and
              preserve the cultural fabric of a region.
            </p>
            <p>
              A punch card app can act as a virtual guide, connecting users with
              local businesses that offer authentic experiences, encouraging
              them to explore and engage with the cultural richness of their
              community.
            </p>
          </section>
        </div>

        <div id="enhanced-community-well-being" className={styles.paragraph}>
          <h3>
            Enhanced Community{" "}
            <span style={{ display: "inline-block" }}>Well-being</span>
          </h3>
          <section>
            <p>
              Supporting local businesses fosters a sense of community and
              social connection.
            </p>
            <p>
              By frequenting neighborhood establishments, individuals forge
              relationships with business owners, staff, and fellow patrons.
              <br />
              These interactions cultivate a strong community spirit and a sense
              of belonging.
            </p>
            <p>
              A punch card app can serve as a platform for community engagement,
              facilitating conversations, reviews, and recommendations among
              users. It encourages social interaction and strengthens the bonds
              between community members, fostering a collective sense of
              well-being.
            </p>
          </section>
        </div>

        <div id="environmental-sustainability" className={styles.paragraph}>
          <h3>Environmental Sustainability</h3>

          <section>
            <p>
              Choosing local businesses over larger chains often leads to a
              reduced environmental footprint.
            </p>
            <p>
              Local businesses typically have shorter supply chains, relying on
              local sources and minimizing transportation distances.
            </p>
            <p>
              This reduces carbon emissions associated with long-haul
              transportation and supports sustainable practices.
            </p>
            <p>
              A punch card app can promote eco-friendly initiatives by
              partnering with businesses that prioritize sustainable sourcing,
              packaging, and waste management. Through the app, users can easily
              identify and support businesses committed to environmental
              sustainability.
            </p>
          </section>
        </div>

        <div id="empowering-small-businesses" className={styles.paragraph}>
          <h3>Empowering Small Businesses</h3>
          <section>
            <p>
              Local businesses are the backbone of entrepreneurship and
              innovation. They provide opportunities for aspiring entrepreneurs
              to bring their ideas to life, spurring creativity and economic
              diversity.
            </p>
            <p>
              Supporting local businesses through a punch card app can provide
              small business owners with a level playing field against larger
              competitors.
            </p>
            <p>
              The app can offer marketing exposure, customer analytics, and
              loyalty program management tools that may have been inaccessible
              otherwise. By leveling the playing field, a punch card app
              empowers small businesses and encourages their growth and success.
            </p>
          </section>
        </div>

        <div
          id="strengthening-local-supply-chains"
          className={styles.paragraph}
        >
          <h3>Strengthening Local Supply Chains</h3>
          <section>
            <p>
              Local businesses often rely on other local suppliers and service
              providers, creating a robust network of interconnected businesses.
            </p>

            <p>
              By supporting these businesses, consumers contribute to the
              vitality and sustainability of the local supply chain.
            </p>

            <p>
              A punch card app can showcase and promote these relationships,
              highlighting the collaborative ecosystem of local businesses.
              Users of the app can discover and engage with the entire network,
              strengthening the interdependencies and fostering economic
              resilience within the community.
            </p>
          </section>
        </div>

        <img
          src="images/oh goodie website DOWNLOAD QR BANNER .png"
          alt="#"
          width={"400px"}
        />

        <div className={styles.additionalText}>
          <p>
            <span>Supporting</span> local businesses through the utilization of
            a punch card app offers a transformative approach to community
            development and engagement. The benefits of such support extend
            beyond economic advantages, encompassing unique experiences,
            cultural preservation, community well-being, environmental
            sustainability, empowerment of small businesses, and strengthened
            local supply chains.
          </p>
          <p>
            <span>By actively patronizing local establishments,</span> consumers
            contribute to the economic growth of their communities. Local
            businesses, in turn, generate employment opportunities, fostering
            economic stability and prosperity. The use of a punch card app
            facilitates this support by incentivizing repeat visits and customer
            loyalty through rewards and discounts, ensuring a continuous flow of
            revenue for local businesses.
          </p>
          <p>
            <span>Moreover,</span> local businesses play a pivotal role in
            preserving the <span>cultural heritage</span> and identity of a
            community. They offer distinct products, services, and experiences
            that reflect the local flavor and charm, contributing to the overall
            vibrancy of the region. Through a punch card app, users can easily
            explore and engage with these unique offerings, immersing themselves
            in the cultural richness of their community.
          </p>
          <p>
            <span>Supporting local businesses</span> also enhances community
            well-being. By frequenting neighborhood establishments, individuals
            foster personal connections with business owners, staff, and fellow
            patrons, nurturing a sense of belonging and social cohesion. The
            punch card app acts as a platform for community engagement,
            facilitating conversations, reviews, and recommendations among
            users, further strengthening the sense of community and collective
            well-being.
          </p>
          <p>
            <span>Environmental sustainability</span> is another significant
            advantage of supporting local businesses. By choosing local
            establishments over larger chains, consumers contribute to the
            reduction of carbon emissions associated with long-haul
            transportation. Local businesses often prioritize sustainable
            practices, and a punch card app can highlight these eco-friendly
            initiatives, encouraging users to support businesses that align with
            their environmental values.
          </p>
          <p>
            <span>Furthermore,</span> supporting local businesses through a
            punch card app empowers small business owners. These businesses
            serve as hotbeds for entrepreneurship and innovation, fostering
            economic diversity and creativity within a community. The app
            provides them with marketing exposure, customer analytics, and
            loyalty program management tools that level the playing field
            against larger competitors, empowering their growth and success.
          </p>
          <p>
            <span>Lastly,</span> the interconnectedness of local businesses
            strengthens the local supply chain. By supporting one local
            business, consumers contribute to the vitality and sustainability of
            an entire network of interconnected businesses. A punch card app can
            showcase and promote these relationships, fostering economic
            resilience and stability within the community.
          </p>
        </div>

        <section style={{ maxWidth: "80%" }}>
          <h4>In conclusion</h4>
          <p>
            supporting local businesses through the utilization of a punch card
            app is a powerful mechanism for community development, economic
            growth, and cultural preservation.
          </p>
          <p>
            By embracing this approach, individuals contribute to the unique
            character and sustainability of their community, fostering a sense
            of pride and collective ownership.
          </p>
          <p>
            Let us actively engage with local businesses, harness the potential
            of technology, and create thriving communities that benefit us all.
          </p>
        </section>
      </main>

      <Footer />
    </React.Fragment>
  );
}

function SummaryItem({ icon, title, text, href }) {
  return (
    <div className={styles.summaryItem}>
      <a className={styles.summaryCircle} href={href}>
        <img src={icon} alt="#" />
      </a>
      <h6>{title}</h6>
      <p>{text}</p>
    </div>
  );
}
