import React from "react";
import ReactPlayer from "react-player";
import Footer from "./Footer";
import { Link } from "react-router-dom";

function App() {
  const [openedMenu, setOpenedMenu] = React.useState(false);

  return (
    <React.Fragment>
      {/* loader  */}
      {/* <div class="loader_bg">
     <div class="loader"><img src="images/loading.gif" alt="#" /></div>
  </div> */}
      {/* end loader */}
      {/* header */}
      <header>
        {/* header inner */}
        <div className="head_top">
          <div className="header">
            <div className="container-fluid">
              <div className="row nav-adjust">
                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col logo_section">
                  <div className="full">
                    <div className="center-desk">
                      <div className="logo">
                        <Link to={"/"}>
                          {/* <img src="images/logo.png" alt="#" /> */}
                          {/* <h1
                            style={{
                              fontSize: "40px",
                              color: "white",
                              fontFamily: "Roundy_Rainbows",
                              whiteSpace: "nowrap",
                            }}
                          > */}
                          oh, goodie!
                          {/* </h1> */}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-9 col-lg-9 col-md-9 col-sm-9">
                  <nav className="navigation navbar navbar-expand-md navbar-dark ">
                    <button
                      className={`navbar-toggler ${!openedMenu && "collapsed"}`}
                      type="button"
                      data-toggle="collapse"
                      data-target="#navbarsExample04"
                      aria-controls="navbarsExample04"
                      aria-expanded={openedMenu ? "true" : "false"}
                      aria-label="Toggle navigation"
                      onClick={() => setOpenedMenu((prev) => !prev)}
                    >
                      <span className="navbar-toggler-icon" />
                    </button>
                    <div
                      className={`navbar-collapse collapse ${
                        openedMenu && "show"
                      }`}
                      id="navbarsExample04"
                    >
                      <ul
                        className="navbar-nav mr-auto"
                        style={{
                          flexWrap: "wrap",
                          maxWidth: "90%",
                          justifyContent: "space-between",
                        }}
                      >
                        {/* <li className="nav-item">
                          <a className="nav-link" href="/">
                            {" "}
                            Home
                          </a>
                        </li> */}
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to={"/supporting-local-businesses"}
                          >
                            Supporting Local Businesses
                          </Link>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link" href="#about">
                            About
                          </a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link" href="#why-us">
                            Why us?
                          </a>
                        </li>
                        {/* <li className="nav-item">
                          <a className="nav-link" href="/">
                            Contact
                          </a>
                        </li> */}
                        <li className="nav-item">
                          <a className="nav-link" href="#shoppers">
                            Oh, Goodie! For Shoppers
                          </a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link" href="#shops">
                            Oh, Goodie! For Shops
                          </a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link" href="#win-win-situation">
                            A win-win situation!
                          </a>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          {/* end header inner */}
          {/* end header */}
          {/* banner */}
          <section className="banner_main">
            <div className="container-fluid">
              <div className="row d_flex">
                <div className="col-md-6">
                  <div className="text-bg">
                    <h1>
                      Simple.
                      <br />
                      Quick.
                      <br />
                      Easy.
                      <br />
                      Supportive.
                    </h1>
                    {/* <h1>A fast, easy and reliable app for generating and increasing business through customer loyalty. </h1> */}
                    <p className="text-intersecting">
                      Support local businesses with a punch card app that is a
                      win-win for shoppers and shopkeepers alike!
                    </p>
                    {/* <a href="#about">Read More</a> */}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="text-img">
                    <figure>
                      {/* <img src="images/box_img.png" alt="#" /> */}
                      <img src="images/new!.png" alt="#" />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </header>
      {/* end banner */}
      {/* business */}
      <div id="about" className="business">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="titlepage">
                <span>YOUR QUICK N’ EASY PUNCH CARD APP</span>
                <h2 style={{ color: "#026064", lineHeight: "45px" }}>
                  for a more supportive shopping experience!
                </h2>
                <ReactPlayer
                  url={"videos/Oh Goodie app promo video 2.5.23.mp4"}
                  controls={true}
                  width={"100%"}
                />
                <p>
                  <br />
                  Shoppers enjoy returning to their favorite shops and get
                  rewarded for their loyalty.
                  <br />
                  Shopkeepers enjoy the benefits of a fast, easy and reliable
                  app for generating and increasing business through customer
                  loyalty.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-10 offset-md-1">
              <div className="row">
                <div className="col-md-12">
                  <div className="business_box">
                    <figure>
                      {/* <img src="images/business_img.jpg" alt="#" /> */}
                      <img src="images/banners/main_banner.png" alt="#" />
                    </figure>
                    <span id="why-us">Why us?</span>
                    <p style={{ color: "#026064" }}>
                      Oh, Goodie! is an easy-to-use app that lets shoppers like
                      you get rewarded by shops who appreciate your business.
                      <br />
                      A punch card is a very effective marketing tool for
                      creating customer loyalty and satisfaction.
                      <br />
                      A punch card is a tried-and-true method of encouraging
                      shoppers to come back to their favorite place of business
                      again and again, and it is a wonderful way of rewarding
                      them.
                      <br />
                      A rewarded customer is a happy customer. <br />A happy
                      customer is a loyal customer.
                      <br />
                      Returning customers are a pillar of a sustainable
                      business.
                      <br />
                    </p>
                    <h3>Simple. Quick. Easy.</h3>
                    <br />
                    <p style={{ color: "#026064" }}>
                      With just one click, Oh, Goodie! will generate a unique QR
                      code on the business phone. Then, customers scan the QR
                      code to receive a punch on their card.
                      <br />
                      <br />
                      It’s that easy.
                    </p>

                    <h3>Rolling with the punches!</h3>
                    <br />
                    <p style={{ color: "#026064" }}>
                      Satisfied shoppers will come back for more and more
                      punches until they receive their well-deserved reward.
                    </p>
                    <p />
                    {/* <a class="read_more" href="#whyUs">Read more</a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end business */}
      {/* Projects */}
      <div className="projects">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="titlepage">
                <span id="shoppers">Oh, Goodie! For Shoppers</span>
                {/* <h3>
                  To get your card punched and get your reward for being a loyal
                  customer, follow these steps:
                </h3>
                <p>
                  Once you have downloaded the Oh, Goodie! app, click on the
                  smiling Oh, Goodie! face icon.
                  <br />
                  Aim the camera at the QR code shown to you at the shop.
                  <br /> This code is unique to each transaction and can only be
                  used once.
                  <br />
                  On your first purchase, Oh, Goodie! will create a punch card
                  for the participating business and automatically punch it.
                  <br />
                  Oh, Goodie! will automatically punch your card every time you
                  scan a unique QR transaction code for that particular offer.
                  <br />
                  As a loyal customer your job is to return to the participating
                  business as many times as needed to get your card fully
                  punched – and get your reward.
                </p> */}
                <figure
                  className="image-inrersecting"
                  style={{ width: "100%", margin: 0 }}
                >
                  {/* <img src="images/projects_img.png" alt="#" /> */}
                  <img src="images/banners/small_banners/1.png" alt="#" />
                </figure>
                <h2 style={{ paddingBottom: "0px", color: "#026064" }}>
                  Advantages for shoppers{" "}
                </h2>
                <p style={{ paddingBottom: "20px", paddingTop: "7px" }}>
                  As returning customers we deserve to be rewarded for our
                  loyalty.
                </p>
                <h3>Shopkeepers love it</h3>
                <p>
                  Shopkeepers love it when we come back to their store again and
                  again – not only because it’s good business but also because
                  it tells them that their goods and services are desirable, and
                  they become proud of all their hard work.
                </p>
                <h3 style={{ marginTop: "20px" }}>
                  Never lose your punch card
                </h3>
                <p>
                  Many punch cards are left unused, and many rewards are not
                  reached or claimed just because we lose our punch cards
                  <br />
                  Let’s face it, many of us have received paper punch cards only
                  to lose or forget them – well, no more!
                </p>
                <h3 style={{ marginTop: "20px" }}>
                  Exercise your shopping power
                </h3>
                <p>
                  Oh, Goodie! manages your punch cards in one app so you can
                  take advantage of your purchasing power.
                  <br />
                  Your punch cards will get punched every time you make a
                  purchase, which gets recorded at the shopkeeper’s side of the
                  app who may offer you additional bonuses and discounts.
                </p>
                <h3 style={{ marginTop: "20px" }}>Convenient</h3>
                <p>
                  Oh Goodie! is with you wherever you go and makes it easy for
                  you to take advantage of the offers provided by generous shop
                  owners vying for your attention.
                  <br />
                  Oh, Goodie! will notify you of participating businesses near
                  you where you can get punched in your… card.
                </p>
                <h3 style={{ marginTop: "20px" }}>Community-building</h3>
                <p>
                  Yes, you read it right. Returning to a place of business can
                  create familiarity with business owners and other customers,
                  which can help create a friendly community.
                  <br />
                  When we as shoppers return to our favorite hangouts, we make
                  new friends and personal bonds.
                  <br />
                  It’s all about people!
                </p>
                <h3 style={{ marginTop: "20px" }}>Help save a tree</h3>
                <p>
                  Replacing paper punch cards with digital ones will help save a
                  tree, or two, or even more.
                  <br />
                  The amount of paper punch cards that are created only to get
                  thrown in the trash is staggering and saddening.
                  <br />
                  This is one small way for us to help keep our planet green and
                  clean.
                  <br />
                  Save paper (and wallet space) with a user-friendly mobile app.{" "}
                </p>
                <h3 style={{ marginTop: "20px" }}>Discover new places</h3>
                <p>
                  New businesses want you to discover them and vie for your
                  attention with offers, specials and discounts.
                  <br />
                  With Oh, Goodie! you could discover new businesses near you –
                  try new coffee shops, restaurants, boutiques, spas and stores
                  that reward their customers.
                  <br />
                  <br />
                  Enjoy!
                </p>

                <DownloadButtonAndAlert />

                {/* <h3 style={{ marginTop: "20px" }}>
                  Word-of-app brand-building
                </h3>
                <p>
                  Satisfied customers are more apt to share a good thing with
                  their family and friends. Oh, Goodie! will help you build your
                  brand with customers who feel appreciated.
                </p> */}
                {/* <h2 style={{ paddingTop: "20px" }}>
                  Advantages for customers:
                </h2>
                <p>
                  As a returning customer you deserve to be rewarded for your
                  loyalty. <br />
                  Let’s face it, many of us have received paper punch cards only
                  to lose or forget them – well, no more! <br />
                  Oh, Goodie! manages your punch cards in one app so you can
                  take advantage of your purchasing power. <br />
                  The app notifies you of participating businesses near you.{" "}
                  <br />
                  Returning to a place of business can create familiarity with
                  business owners and other customers, which can help create a
                  friendly community. <br />
                  Save paper (and wallet space) with a user-friendly mobile app.{" "}
                  <br />
                  Discover new businesses near you – try participating coffee
                  shops, restaurants, boutiques, spas and stores you may not
                  have known that reward their customers.
                </p> */}

                <span style={{ paddingTop: "120px" }} id="shops">
                  Oh, Goodie! For Shops
                </span>
                <p style={{ fontSize: "16px" }}>
                  Offer shoppers an awesome deal and turn them into loyal
                  customers who love and appreciate you.
                </p>
                <figure style={{ width: "100%", margin: 0 }}>
                  {/* <img src="images/projects_img.png" alt="#" /> */}
                  <img src="images/banners/small_banners/2.png" alt="#" />
                </figure>
                {/* <h2>Business incentives to use Oh, Goodie!</h2> */}
                <p style={{}}>
                  Grow your business by providing customers with a more
                  enjoyable shopping experience.
                  <br />
                  <br />
                  Punch cards are a tried, tested, and true marketing tool that
                  benefits both you and your customers.
                  <br />
                  <br />
                  Consumers deserve to be rewarded for their loyalty and your
                  business will be able to satisfy their desire to be
                  appreciated and save a bit of money (which never hurt anyone).
                  <br />
                  <br />
                  Your happy customers can share their positive experiences on
                  popular social networks, which will increase your business
                  visibility.
                  <br />
                  <br />
                  Customers get their cards punched easily and immediately,
                  using a built-in QR scanner.
                  <br />
                  <br />
                  You can make the awesome deal a time-limited offer, so that
                  shoppers will be encouraged to revisit your fine establishment
                  within a specified time period.
                  <br />
                  <br />
                  Oh, Goodie! sends friendly notifications to customers at
                  convenient times and encourages them to visit you more often.
                  <br />
                  <br />
                  Full updates on actions that customers perform in the app
                  (without infringing on their privacy).
                  <br />
                  <br />
                  Real-time viewing of all customer punch cards.
                  <br />
                  <br />
                  Only one punch per purchase – generate a brand new QR code
                  that is unique to each transaction.
                  <br />
                  <br />
                  Quick-and-easy sign-up with several options.
                  <br />
                  <br />
                </p>
                {/* <figure>
                  <img src="images/banners/small_banners/3.png" alt="#" />
                </figure> */}
                <h2 style={{ paddingTop: "40px", color: "#026064" }}>
                  What can you offer in your punch card deal?
                </h2>
                <h4 style={{ color: "#026064" }}>Anything, really.</h4>
                <section>
                  <p>
                    <br />
                    <br />
                    You can offer your customers to buy a product you sell or a
                    service you provide.
                    <br />
                    <br />
                    You can even offer a reward to customers just for visiting
                    your store or place of business.
                    <br />
                    <br />
                    As a reward you can offer a product, a service, a discount,
                    or something that is so unique, only you can think of it. It
                    could even be a song.
                    <br />
                    <br />
                    Be creative and unique and you could find yourself serving a
                    crowd of loyal customers that come back again and again.
                    <br />
                    <br />
                    Create your very own, customized punch card. Select from ’10
                    punches = reward,’ ‘9 punches = reward’ or ‘5 punches =
                    reward,’ and watch how shoppers come back again and again to
                    receive their well-deserved reward.
                    <br />
                    <br />
                    You can upload your shop logo onto the punch card, which
                    your loyal shoppers will carry around with them and share
                    with friends and family.
                    <br />
                    <br />
                    Oh, Goodie! will remind shoppers to visit your fine
                    establishment and get punched in the… card… again.
                    <br />
                    <br />
                    The beautiful thing is that once more and more shoppers come
                    back to your shop, they will get to know other shoppers and
                    feel they are a part of a community.
                    <br />
                    <br />
                  </p>
                  <div style={{}}>
                    It’s a win-win situation! You just can’t lose.
                    <br />
                    <br />
                    Oh, Goodie! is fast, easy and reliable.
                  </div>
                  <br />
                  <div style={{ color: "#026064", fontWeight: "bold" }}>
                    So, what are you waiting for! Let’s roll with the punches…
                    Download yours right now.
                  </div>
                  {/* <DownloadButtonAndAlert noAlert padding="0px" /> */}
                  <h2 style={{ paddingTop: "70px", color: "#026064" }}>
                    Advantages for businesses{" "}
                  </h2>
                  <figure>
                    {/* <img src="images/projects_img.png" alt="#" /> */}
                    <img src="images/banners/large_banners/3.png" alt="#" />
                  </figure>
                  <h3>More business</h3>
                  <p>
                    Loyal customers are more apt to get to know your merchandise
                    and/or services better and spend more money.
                  </p>
                  <h3 style={{ marginTop: "20px" }}>
                    Word-of-app brand-building{" "}
                  </h3>
                  <p>
                    Satisfied customers are more apt to share a good thing with
                    their family and friends.
                    <br />
                    Oh, Goodie! will help you build your brand with customers
                    who feel appreciated.
                  </p>
                  <h3 style={{ marginTop: "20px" }}>Convenience </h3>
                  <p>
                    Your customers will thank you for giving them a convenient
                    way to be rewarded for being loyal to you.
                    <br />
                    As a shopkeeper you can offer a deal by designing your own
                    punch card.
                  </p>
                  <h3 style={{ marginTop: "20px" }}>Customizable </h3>
                  <p>
                    {/* Choose from several deals*: from buy-5-get-one-free,
                    buy-9-get-one-free or buy-10-get-one-free. */}
                    Choose from several deals*: from buy-1-get-one-free to
                    buy-10-get-one-free
                    <br />
                    Select the timeframe during which shoppers may get their
                    cards punched and receive their reward.
                    <br />
                    Display your business name, brand logo and colors on a
                    customizable digital punch card.
                    <br />
                  </p>
                  <p style={{ fontSize: "12px" }}>
                    *Stay tuned for updates on deal offers
                  </p>
                  <h3 style={{ marginTop: "20px" }}>It feels good</h3>
                  <p>
                    Each punch of the card creates excitement and getting the
                    reward generates a feeling of sweet satisfaction and
                    appreciation among loyal customers.
                    <br />
                    There’s nothing like seeing a broad smile on your customers’
                    faces, is there?
                  </p>
                  <h3 style={{ marginTop: "20px" }}>Full control</h3>
                  <p>
                    You decide what reward you will give loyal customers and
                    during what timeframe.
                  </p>
                  <h3 style={{ marginTop: "20px" }}>
                    Polite, non-intrusive notifications
                  </h3>
                  <p>
                    Oh, Goodie! politely notifies your customers to come back
                    again to make a purchase and get their cards punched.
                    <br />
                    Customers are happy to be reminded that their punch card is
                    filling up. It’s in their best interest.
                    <br />
                    The app alerts customers about nearby participating
                    businesses like yours and encourages them to visit, make a
                    purchase and get closer to their anticipated reward.
                  </p>
                  <h3 style={{ marginTop: "20px" }}>
                    Save money and counterspace
                  </h3>
                  <p>
                    No more printing paper cards and putting them out on the
                    counter, which takes up space, and no more holepunches to
                    misplace.
                  </p>
                  <DownloadButtonAndAlert padding="40px" />
                </section>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-10 offset-md-1">
              <div className="row">
                <div className="col-md-6 offset-md-3">
                  <div className="projects_box ">
                    {/* <figure>
                      <img src="images/bwink_msc_10_single_11.jpg" alt="#" />
                    </figure> */}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="projects_box ">
                    {/* <h2> Business incentives to use Oh, Goodie!</h2>
                    <p>
                      Punch cards are a tried, tested, and true marketing tool
                      that benefit both you and your customers. <br />
                      Consumers deserve to be rewarded for their loyalty and
                      your business will be able to satisfy their desire to save
                      money.
                      <br />
                      <br /> Your happy customers can share their positive
                      experience on popular social networks, which will increase
                      your business visibility. <br />
                      <br />
                      Customers get their cards punched easily and immediately,
                      using a built-in scanner. <br />
                      <br />
                      The app sends friendly notifications to customers at
                      convenient times and encourages them to visit you more
                      often. <br />
                      <br />
                      Full updates on actions that customers perform in the app.{" "}
                      <br />
                      <br />
                      Real-time viewing of all customer punch cards (without
                      infringing on their privacy). <br />
                      <br />
                      Only one punch per purchase – generate a brand new QR code
                      that is singular to each transaction. Quick-and-easy
                      sign-up with several options. <br />
                      <br />
                    </p> */}

                    <span style={{ paddingTop: "80px" }} id="win-win-situation">
                      A win-win situation!
                    </span>
                    <p style={{ color: "#666666" }}>
                      As a shopper you deserve to be rewarded for your loyalty,
                      and as a shopkeeper you can attract wonderful new loyal
                      shoppers – Oh, Goodie! has it good for everyone.
                    </p>
                    <figure>
                      {/* <img src="images/projects_img.png" alt="#" /> */}
                      <img src="images/banners/large_banners/2.png" alt="#" />
                    </figure>
                    <p style={{ color: "#666666" }}>
                      One simple, easy-to-use app allows you to interact with
                      shops – cafes, restaurants, spas, hair salons, ice cream
                      vendors, pizzerias, fruit stands, and any participating
                      businesses – and get rewarded for coming back.
                      <br />
                      <br />
                      Oh, Goodie! is downloadable by both the shop and the
                      shopper for a truly mutual experience.
                      <br />
                      <br />
                      Shoppers are encouraged to scan the unique QR code that
                      the shopkeeper has generated on the shop’s phone.
                      <br />
                      It’s like a virtual friendly handshake.
                      <br />
                      <br />
                      The shop’s punch card is then downloaded to your app so
                      you can track how many punches you have left until you get
                      your well-deserved reward.
                      <br />
                      <br />
                      As a shopper you prefer going back to that same old shop
                      that gave you excellent customer service with a smile and
                      with that something extra that money just can’t buy.
                      <br />
                      <br />
                      As a shopkeeper you want to create for your customers a
                      wonderful shopping experience that lets them know they are
                      appreciated. You want them to spend quality time at your
                      shop and leave with a smile.
                    </p>
                    <figure>
                      {/* <img src="images/projects_img.png" alt="#" /> */}
                      <img src="images/banners/small_banners/4.png" alt="#" />
                    </figure>
                    <p style={{ color: "#666666" }}>
                      In this hectic world of ours, we all need a sense of
                      community – a place where you can go back to because you
                      are wanted and appreciated – a place where they remember
                      your name and what you like.
                      <br />
                      <br />
                      Who am I buying from? Who am I selling to?
                      <br />
                      <br />
                      It’s time to get close and get to know each other better.
                      <br />
                      <br />
                      Oh, Goodie! allows shoppers and shopkeepers to interact on
                      a personal level.
                      <br />
                      <br />
                      Keep track of all your punch cards in one, convenient,
                      paperless app.
                      <br />
                      <br />
                      Visit your favorite shop and recommend that the friendly
                      shopkeeper download Oh, Goodie! today.
                      <br />
                      <br />
                      Oh, Goodie! is a win-win for shoppers and shops alike.
                    </p>
                    <DownloadButtonAndAlert />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end projects */}
      {/* Testimonial */}
      {/* <div className="section">
        <div className="container">
          <div id className="Testimonial">
            <div className="row">
              <div className="col-md-12">
                <div className="titlepage">
                  <h2>Testimonial</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <div className="Testimonial_box">
                  <i>
                    <img src="images/plan1.png" alt="#" />
                  </i>
                </div>
              </div>
              <div className="col-md-9">
                <div className="Testimonial_box">
                  <h4>Donals</h4>
                  <p>
                    Tof Lorem Ipsum, you need to be There are many variations of
                    passages of Lorem Ipsum available, but the majority have
                    suffered alteration in some form, by injected humour, or
                    randomised words which don't look even slightly believable.
                    If you are going to use a pass <br />
                    age of Lorem Ipsum, you need to be
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* end Testimonial */}
      {/* contact */}
      {/* <div id="contact" className="contact">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="titlepage">
                <h2>Contact us</h2>
                <span>
                  There are many variations of passages of Lorem Ipsum
                  available, but the{" "}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12 ">
              <form className="main_form ">
                <div className="row">
                  <div className="col-md-12 ">
                    <input
                      className="form_contril"
                      placeholder="Name "
                      type="text"
                      name="Name "
                    />
                  </div>
                  <div className="col-md-12">
                    <input
                      className="form_contril"
                      placeholder="Phone Number"
                      type="text"
                      name=" Phone Number"
                    />
                  </div>
                  <div className="col-md-12">
                    <input
                      className="form_contril"
                      placeholder="Email"
                      type="text"
                      name="Email"
                    />
                  </div>
                  <div className="col-md-12">
                    <textarea
                      className="textarea"
                      placeholder="Message"
                      type="text"
                      name="Message"
                      defaultValue={""}
                    />
                  </div>
                  <div className="col-sm-12">
                    <button className="send_btn">Send</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div> */}
      {/* end contact */}
      {/*  footer */}
      <Footer />
    </React.Fragment>
  );
}

function DownloadButtonAndAlert({ noAlert = false, padding = "20px" }) {
  return (
    <div className="projects_box" style={{ marginTop: padding }}>
      {!noAlert && <h3>And all this for free!*</h3>}

      <a
        target="_blank"
        rel="noreferrer"
        className="read_more"
        href="https://onelink.to/c7m3e5"
        style={{
          margin: "20px 0 20px 0",
        }}
      >
        Download now!
      </a>
      {!noAlert && (
        <h6>
          *A freemium version will be available soon for an even fuller user
          experience.
        </h6>
      )}
    </div>
  );
}

export default App;
